import { Link } from 'gatsby';
import React from 'react';
import team1 from '../../assets/images/our-people/avadhani.jpg';
import team10 from '../../assets/images/our-people/divyarani.jpg';
import team11 from '../../assets/images/our-people/charishma.jpg';
import team12 from '../../assets/images/our-people/kruthika.jpg';
import team13 from '../../assets/images/our-people/mrunalini.jpg';
import team14 from '../../assets/images/our-people/srinidhi.jpg';
import team2 from '../../assets/images/our-people/vijay.jpg';
import team3 from '../../assets/images/our-people/shoubhagya.jpg';
import team4 from '../../assets/images/our-people/nithin.jpg';
import team5 from '../../assets/images/our-people/sanjay.jpg';
import team6 from '../../assets/images/our-people/prabha.jpg';
import team7 from '../../assets/images/our-people/juber.jpg';
import team8 from '../../assets/images/our-people/divya.jpg';

const TeamOne = () => {
  return (
    <section className='team-one team-one__become-teacher' id='team-section'>
      <div className='container'>
        <div className='block-title text-center'>
          <h2 className='block-title__title'>
            OUR PEOPLE <br />
          </h2>
        </div>
        <div className='row'>
          <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12'>
            <div className='team-one__single'>
              <div className='team-one__image'>
                <img src={team1} alt='' />
              </div>
              <div className='team-one__content avadhani'>
                <h2 className='team-one__name'>
                  <Link to='/advocates/avadani'>
                    Mr. V.S.R. Avadhani. BSC.,BL.
                  </Link>
                </h2>
                <p className='team-one__designation'>
                  Senior Partner, Senior Consultant and Advocate
                </p>
              </div>
            </div>
          </div>
          <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12'>
            <div className='team-one__single'>
              <div className='team-one__image'>
                <img src={team2} alt='' />
              </div>
              <div className='team-one__content vijay'>
                <h2 className='team-one__name'>
                  <Link to='/advocates/vijay'>
                    Mr. K Vijay Bhaskar Reddy, LLM.,{' '}
                  </Link>
                </h2>
                <p className='team-one__designation'>
                  Partner, Sr. Consultant and Advocate
                </p>
              </div>
            </div>
          </div>
          <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12'>
            <div className='team-one__single'>
              <div className='team-one__image'>
                <img src={team3} alt='' />
              </div>
              <div className='team-one__content valli'>
                <h2 className='team-one__name'>
                  <Link to='/advocates/soubhagya'>
                    Mrs. V Soubhagya Valli. LLM (UK)
                  </Link>
                </h2>
                <p className='team-one__designation'>
                  Partner, Consultant & Advocate
                </p>
              </div>
            </div>
          </div>
          <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12'>
            <div className='team-one__single'>
              <div className='team-one__image'>
                <img src={team4} alt='' />
              </div>
              <div className='team-one__content'>
                <h2 className='team-one__name'>
                  <Link to='/advocates/nithin'>
                    Mr. Nithin Chowdary Pavuluri. B.A., LLB.Hons.
                  </Link>
                </h2>
                <p className='team-one__designation'>
                  Partner, Consultant & Advocate
                </p>
              </div>
            </div>
          </div>
          <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12'>
            <div className='team-one__single'>
              <div className='team-one__image'>
                <img src={team5} alt='' />
              </div>
              <div className='team-one__content sanjay'>
                <h2 className='team-one__name'>
                  <Link to='/advocates/sanjay'>
                    Mr. Sanjay Kamle, B.A., LLB.,
                  </Link>
                </h2>
                <p className='team-one__designation'>Advocate</p>
              </div>
            </div>
          </div>
          <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12'>
            <div className='team-one__single'>
              <div className='team-one__image'>
                <img src={team7} alt='' />
              </div>
              <div className='team-one__content juber'>
                <h2 className='team-one__name'>
                  <Link to='/advocates/juber'>
                    Mr. S.A.Juber Hussain. B.A. LLB. (HONS).
                  </Link>
                </h2>
                <p className='team-one__designation'>Advocate</p>
              </div>
            </div>
          </div>
          <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12'>
            <div className='team-one__single'>
              <div className='team-one__image'>
                <img src={team8} alt='' />
              </div>
              <div className='team-one__content divya'>
                <h2 className='team-one__name'>
                  <Link to='/advocates/divya'>
                    {' '}
                    Ms. Divya Dokka. BTech., LLB.
                  </Link>
                </h2>
                <p className='team-one__designation'>Advocate</p>
              </div>
            </div>
          </div>

          <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12'>
            <div className='team-one__single'>
              <div className='team-one__image'>
                <img src={team10} alt='' />
              </div>
              <div className='team-one__content divya'>
                <h2 className='team-one__name'>
                  <Link to='/advocates/divyaRani'>Mrs. R. Divya Rani, LLM</Link>
                </h2>
                <p className='team-one__designation'>Advocate</p>
              </div>
            </div>
          </div>
          <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12'>
            <div className='team-one__single'>
              <div className='team-one__image'>
                <img src={team11} alt='' />
              </div>
              <div className='team-one__content divya'>
                <h2 className='team-one__name'>
                  <Link to='/advocates/charishma'>
                    Ms. Charishma Atla, LLB.
                  </Link>
                </h2>
                <p className='team-one__designation'>Advocate</p>
              </div>
            </div>
          </div>
          <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12'>
            <div className='team-one__single'>
              <div className='team-one__image'>
                <img src={team12} alt='' />
              </div>
              <div className='team-one__content divya'>
                <h2 className='team-one__name'>
                  <Link to='/advocates/kruthika'>
                    Ms. Kruthika Kandhala. BBA LLB
                  </Link>
                </h2>
                <p className='team-one__designation'>Advocate</p>
              </div>
            </div>
          </div>
          {/* <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12'>
            <div className='team-one__single'>
              <div className='team-one__image'>
                <img src={team13} alt='' />
              </div>
              <div className='team-one__content divya'>
                <h2 className='team-one__name'>
                  <Link to='/advocates/mrunalini'>Mrunalini Eitikela, LLM</Link>
                </h2>
                <p className='team-one__designation'>Advocate</p>
              </div>
            </div>
          </div> */}
          <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12'>
            <div className='team-one__single'>
              <div className='team-one__image'>
                <img src={team14} alt='' />
              </div>
              <div className='team-one__content divya'>
                <h2 className='team-one__name'>
                  <Link to='/advocates/srinidhi'>
                    B. Srinidhi. BBA LLB (Hons.)
                  </Link>
                </h2>
                <p className='team-one__designation'>Advocate</p>
              </div>
            </div>
          </div>
          {/* <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12'>
            <div className='team-one__single'>
              <div className='team-one__image'>
                <img src={team9} alt='' />
              </div>
              <div className='team-one__content'>
                <h2 className='team-one__name'>
                  <Link to='/advocates/venkat'>
                    B. Venkat Durga Pradeep. BCOM., LLB.
                  </Link>
                </h2>
                <p className='team-one__designation'>Advocate</p>
              </div>
            </div>
          </div> */}

          {/* <div className='col-xl-4 col-lg-6 col-md-6 col-sm-12'>
            <div className='team-one__single'>
              <div className='team-one__image'>
                <img src={team9} alt='' />
              </div>
              <div className='team-one__content'>
                <h2 className='team-one__name'>
                  <Link to='/advocates/venkat'>
                    B. Venkat Durga Pradeep. BCOM., LLB.
                  </Link>
                </h2>
                <p className='team-one__designation'>Advocate</p>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </section>
  );
};

export default TeamOne;
